<template>
  <router-view :key="$route.path + $route.query.vid + $route.query.id" />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0px;
  font-size: 12px;
  color: #6c6c6c;
}
a {
  text-decoration: none;
  color: #6c6c6c;
}
</style>
